export const nfts = {
	"skullys": {
		"abi": require("../abis/skullys.json"),
		"address": "0x25ff0d27395A7AAD578569f83E30C82a07b4ee7d",
		"claim-abi": require("../abis/cult.json"),
		"claim-address": "0x4Ed9C2aba77141433c462B8EE835B7fC39Ec449A",
		"claim-icon": require("../images/token-icons/cult.jpg_medium"),
		"img": require("../images/skully-img.png")
	},
	"popskullys": {
		"abi": require("../abis/skullys.json"),
		"address": "0x1b60B6daA371F5066bd8C1DC032627bf1f4E95df",
		"claim-abi": require("../abis/cult.json"),
		"claim-address": "0x718670c88F2daa222d44908C1c253253FF96aE21",
		"claim-icon": require("../images/token-icons/xcult.jpg_medium"),
		"img": require("../images/popskully-img.png")
	},
	"ghostlys": {
		"abi": require("../abis/skullys.json"),
		"address": "0x4EaB37d5C62fa3bff8F7A5FFce6a88cFC098749C",
		"claim-abi": require("../abis/cult.json"),
		"claim-address": "0x718670c88F2daa222d44908C1c253253FF96aE21",
		"claim-icon": require("../images/token-icons/xcult.jpg_medium"),
		"img": require("../images/ghostly-img.png")
	}
}